import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import css from './Modal.scss';

const Modal = (props) => {
  const preventClose = props.preventClose || false;
  return (
    <AnimatePresence>
      {props.isOpen && (
        <React.Fragment>
          <motion.div
            className={[css.fullScreen, css.Overlay].join(' ')}
            initial="overlayInitial"
            animate="overlayAnimate"
            exit="overlayExit"
            transition={{
              duration: 1
            }}
            variants={{
              overlayInitial: {
                opacity: 0,
                width: 0,
                height: 0,
                left: '50%',
                top: '50%',
                borderRadius: '100%',
              },
              overlayAnimate: {
                opacity: 0.3,
                width: '100vw',
                height: '100vh',
                left: 0,
                top: 0,
                borderRadius: '0%',
              },
              overlayExit: {
                opacity: 0,
                width: 0,
                height: 0,
                left: '50%',
                top: '50%',
                borderRadius: '100%',
                transition: {
                  delay: 0.5,
                  duration: 1,
                }
              }
            }}
            onClick={() => {
              if (!preventClose) {
                props.setIsOpen(false);
              }
            }}
          />
          <motion.div
            className={[css.fullScreen, css.Wrapper].join(' ')}
          >
            <motion.div
              className={[props.className, css.Modal].join(' ')}
              initial="modalInitial"
              animate="modalAnimate"
              exit="modalExit"
              transition={{
                duration: 1,
              }}
              variants={{
                modalInitial: {
                  scaleY: 0,
                  transition: {
                    delay: 1,
                    delayChildren: 1
                  }
                },
                modalAnimate: {
                  scaleY: 1,
                  transition: {
                    delay: 1,
                    delayChildren: 1
                  }
                },
                modalExit: {
                  scaleY: 0,
                  transition: {
                    duration: 0.25,
                  }
                }
              }}
            >
              {!preventClose && (
                <button
                  className={css.Close}
                  onClick={() => {
                    props.setIsOpen(false);
                  }}
                >
                  Close
                </button>
              )}
              {props.children}
            </motion.div>
          </motion.div>
        </React.Fragment>
      )}
    </AnimatePresence>
  );
};

export default Modal;
